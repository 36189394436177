import React, { useEffect, useState } from 'react'
import LiveTvPlayer from '../liveTvPlayer'
import LiveTvSearch from './search'
import { LiveTvMobileView } from './livetv.styled'
import LiveTVHoc from '../livetv/liveTvHoc'

import './livetv.scss'

const MobileLiveTv = ({
  hideLiveTvMobile,
  isAsharqAppPopupVisible = false
}) => {
  const [fixedPositionClass, setFixedPositionClass] = useState('')

  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  const onScroll = () => {
    if (window.scrollY) {
      const winscroll = window.scrollY
      // height of the mobile headline is 63, make the live tv fixed after scrolling of mobile headline
      if (winscroll > 63) {
        if (fixedPositionClass === '') {
          setFixedPositionClass('fixed-mobile-live-tv-player')
        }
      } else {
        setFixedPositionClass('')
      }
    }
  }

  return (
    <div
      className={fixedPositionClass}
      style={
        fixedPositionClass !== ''
          ? {
              top: isAsharqAppPopupVisible ? '209px' : '119px'
            }
          : {}
      }
    >
      <LiveTVHoc
        showSearch
        mobileView
        onMobileViewClose={() => hideLiveTvMobile()}
        liveTvComponent={
          <LiveTvMobileView>
            <LiveTvPlayer
              showSocialShareIcons
              mobileView
              onMobileViewClose={() => hideLiveTvMobile()}
            />
          </LiveTvMobileView>
        }
      />
    </div>
  )
}

export default MobileLiveTv
