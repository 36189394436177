import React, { useState, useEffect } from 'react'
import { useDrop } from 'react-dnd'

import styled from 'styled-components'

const OverlayPartStyled = styled.div`
  display: flex;
  flex: 1;
  font-size: 60px;
  align-items: center;
  justify-content: center;
  color: white;
  background: ${props => props.background};
`

const OverlayContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.8;
  z-index: 1000;
  display: flex;
`

const RotateDiv = styled.div`
  transform: rotate(${props => props.angle}deg);
`

const OverlayPart = ({ angle = 0, text, onDrop, color, articleId }) => {
  const [{ isOver, isDragging }, drop] = useDrop({
    accept: 'CARD',
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      isDragging: !!monitor.getItem()
    }),
    drop(item, monitor, component) {
      onDrop(item)
    }
  })

  if (!isDragging) {
    return null
  }

  return (
    <OverlayPartStyled
      id="overlay1"
      ref={drop}
      background={isOver ? 'blue' : color}
    >
      <RotateDiv angle={angle}>{text}</RotateDiv>
    </OverlayPartStyled>
  )
}

const DropOverlay = ({
  isLatestNews,
  onlyRight,
  onDropLeft,
  onDropRight,
  onDropCenter,
  onDropBottom,
  disableDropLeft,
  disableDropRight,
  disableDropBottom
}) => {
  if (!isLatestNews) {
    return (
      <OverlayContainer>
        {disableDropLeft ? null : (
          <OverlayPart text="<" onDrop={onDropLeft} color="green" />
        )}
        {isLatestNews ? null : (
          <OverlayPart text="" onDrop={onDropCenter} color="yellow" />
        )}
        {isLatestNews || disableDropRight ? null : (
          <OverlayPart text=">" onDrop={onDropRight} color="green" />
        )}
        {isLatestNews ? <div style={{ flex: 2 }} /> : null}
      </OverlayContainer>
    )
  }

  return (
    <OverlayContainer>
      {disableDropLeft ? null : (
        <OverlayPart text="<" onDrop={onDropLeft} color="green" />
      )}
      <div
        style={{
          display: 'flex',
          flex: 2,
          flexDirection: 'column',
          marginRight: 5
        }}
      >
        <div style={{ flex: 8 }} />
        {disableDropBottom ? null : (
          <OverlayPart
            onDrop={onDropBottom}
            angle={-90}
            color="green"
            text=">"
          />
        )}
      </div>
    </OverlayContainer>
  )
}

export default DropOverlay
