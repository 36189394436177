import { Link } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'
import loadable from '@loadable/component'
import TimeAgo from 'javascript-time-ago'
import ar from 'javascript-time-ago/locale/ar'
import { sendEventItemClick } from '../../utils/sendEvent'
import PaginationWrapper from './paginationWrapper'
import MobileLiveTv from '../livetv/mobileLiveTv'

import { parseISO, differenceInHours } from 'date-fns'
import DateTimeService from '../../utils/common'

import {
  Container,
  Title,
  NewsTime,
  NewsTitle,
  NewsRow,
  NewsContainer
} from './styles'
import GridDropOverlay from '../grid/dropOverlay.js'

const Footer = loadable(() => import('../footer/footer'))

TimeAgo.addLocale(ar)

const LatestNews = ({
  data,
  scrollable,
  fetching,
  titleStyles = {},
  rowStyles = {},
  fullSize = false,
  onDropLeft,
  onDropBottom,
  disableDropLeft,
  disableDropBottom,
  pagination = false,
  pageLimit = 0,
  activePage = 1,
  setCurrentPage = () => {},
  totalHeadlines,
  page,
  quickLinks,
  hideLiveTvMobile
}) => {
  const container = useRef()
  const title = useRef()
  const [isFocused, setFocus] = useState(false)
  const [titleHeight, setTitleHeight] = useState()
  const timeAgo = new TimeAgo('ar-LY')
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (title.current) {
      setTitleHeight(title.current.scrollHeight)
    }
  })

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 4000)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    if (container.current) {
      container.current.scrollTop = 0
      if (container.current.focus) {
        container.current.focus()
      }
    }
  }, [page])

  useEffect(() => {
    let interval

    if (scrollable && loaded) {
      interval = setInterval(() => {
        if (container.current && !isFocused) {
          const scrollHeight = container.current.scrollHeight
          const scrollTop = container.current.scrollTop

          if (scrollHeight - scrollTop !== container.current.clientHeight) {
            container.current.scrollTop = Math.min(scrollTop + 1, scrollHeight)
          } else {
            container.current.scrollTop = 0
          }
        }
      }, 50)
    }

    return () => clearInterval(interval)
  }, [scrollable, isFocused, loaded])

  const renderDate = (date) => {
    const now = new Date()
    const articleDate = parseISO(date)

    return differenceInHours(now, articleDate) > 2 ? (
      <>
        <time dateTime={DateTimeService.formattedDateTime(articleDate)}>
          {DateTimeService.formatInTimeZoneDate(
            date,
            'eeee dd MMMM yyyy HH:mm'
          )}
        </time>
      </>
    ) : (
      timeAgo.format(new Date(date))
    )
  }

  const renderNews = () => {
    if (fetching) {
      return (
        <NewsContainer style={{ marginTop: titleHeight || 45 }}>
          ...
        </NewsContainer>
      )
    }

    return (
      <NewsContainer
        fullSize={fullSize}
        marginTop={titleHeight || 45}
        ref={container}
        onMouseEnter={() => setFocus(true)}
        onMouseLeave={() => setFocus(false)}
      >
        {data.map(({ id, title, createdAt, publishedAt }, index) => (
          <NewsRow fullSize={fullSize} style={rowStyles} key={id}>
            <NewsTitle fullSize={fullSize}>
              <Link
                to={`/latest-news/${id}/`}
                onClick={() => {
                  sendEventItemClick({
                    itemId: id,
                    pos: {
                      column: 1,
                      row: index + 1
                    },
                    itemType: 'headline'
                  })

                  dataLayer.push({
                    event: 'gtm.click',
                    category: 'headline',
                    publish_date: `${publishedAt}`
                  })
                }}
              >
                {title}
              </Link>
            </NewsTitle>
            <NewsTime>{renderDate(createdAt)}</NewsTime>
          </NewsRow>
        ))}
      </NewsContainer>
    )
  }

  return (
    <Container fullSize={fullSize}>
      {quickLinks.showLiveTvMobileInGrid && (
        <div
          style={{
            width: '100%',
            zIndex: 1
          }}
        >
          <MobileLiveTv
            hideLiveTvMobile={hideLiveTvMobile}
            isAsharqAppPopupVisible={quickLinks.isAsharqAppPopupVisible}
          />
        </div>
      )}
      <Title fullSize={fullSize} ref={title} style={titleStyles}>
        <Link to="/latest-news/">آخر الأخبار</Link>
      </Title>

      {renderNews()}

      {onDropLeft ? (
        <GridDropOverlay
          disableDropLeft={disableDropLeft}
          disableDropBottom={disableDropBottom}
          onDropBottom={onDropBottom}
          onDropLeft={onDropLeft}
          isLatestNews
        />
      ) : null}
      {pagination && data && data.length > 0 && (
        <>
          <PaginationWrapper
            pageLimit={pageLimit}
            activePage={activePage}
            setCurrentPage={setCurrentPage}
            totalHeadlines={totalHeadlines}
          />
          <Footer />
        </>
      )}
    </Container>
  )
}

export default LatestNews
