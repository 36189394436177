import { Link } from 'gatsby'
import React, { useEffect, useState, useRef } from 'react'

import {
  MContainer,
  MExpand,
  MNewsContainer,
  MNewsTitle,
  Arrow
} from './styles'
import { sendEventItemClick } from '../../utils/sendEvent'
import useOnScreen from '../../utils/useOnScreen'
import arrow from './assets/arrow.svg'
import plus from './assets/plus.svg'

const LatestNewsMobile = ({ data, fetching, updateLatestNewsVisibility }) => {
  const [selected, setSelected] = useState({})
  const mTitle = useRef()
  const latestNewsRef = useRef()
  const isLatestNewsInViewport = useOnScreen(latestNewsRef)
  const fadeOutDuration = 400

  useEffect(() => {
    if (!selected.id && data.length > 0) {
      setSelected(data[0])
    }
  })

  useEffect(() => {
    const interval = setInterval(() => {
      handleArrowClick(1)
    }, 5000)

    return () => clearInterval(interval)
  })

  useEffect(() => {
    updateLatestNewsVisibility(isLatestNewsInViewport)
  }, [isLatestNewsInViewport])

  const handleArrowClick = change => {
    if (!selected.id) {
      return
    }

    let currIndex = data.findIndex(({ id }) => id === selected.id)
    currIndex += change

    if (currIndex <= 0) {
      currIndex = data.length - 1
    }

    currIndex = currIndex % data.length

    if (mTitle.current) {
      mTitle.current.classList.add('fadeOut')

      setTimeout(() => {
        setSelected(data[currIndex])
        if (mTitle.current) {
          mTitle.current.classList.remove('fadeOut')
        }
      }, fadeOutDuration)
    }
  }

  const myIndex = data.findIndex(({ id }) => id === selected.id)

  return (
    <MContainer ref={latestNewsRef}>
      <Link to="/latest-news" style={{ textDecoration: 'none' }}>
        <MExpand>
          آخر الأخبار
          <img src={plus} alt="plus" loading="lazy" />
        </MExpand>
      </Link>

      <MNewsContainer>
        <Arrow onClick={() => handleArrowClick(1)}>
          <img src={arrow} alt="arrow" loading="lazy" />
        </Arrow>

        <MNewsTitle fadeOutDuration={fadeOutDuration} ref={mTitle}>
          <Link
            to={fetching ? `/latest-news/` : `/latest-news/${selected.id}/`}
            onClick={() => {
              sendEventItemClick({
                itemId: selected.id,
                pos: {
                  column: myIndex + 1,
                  row: 1
                },
                itemType: 'headline'
              })
            }}
          >
            {fetching ? '...' : selected.title}
          </Link>
        </MNewsTitle>

        <Arrow rotate={1} onClick={() => handleArrowClick(-1)}>
          <img src={arrow} alt="arrow" loading="lazy" />
        </Arrow>
      </MNewsContainer>
    </MContainer>
  )
}

export default LatestNewsMobile
