import React from 'react'
import Pagination from 'rc-pagination'
import { PaginationWrapperContainer } from './styles'
import { ArrowNextIcon, ArrowPrevIcon } from '../socialcontrols/icons'

const PaginationWrapper = ({
  pageLimit = 0,
  activePage = 1,
  setCurrentPage = () => {},
  totalHeadlines
}) => {
  return (
    <PaginationWrapperContainer>
      <Pagination
        hideOnSinglePage
        locale="ar"
        pageSize={pageLimit}
        total={totalHeadlines}
        current={activePage}
        onChange={num => setCurrentPage(num)}
        nextIcon={
          <ArrowNextIcon theme="grey" width="16px" height="16px" flip={true} />
        }
        prevIcon={
          <ArrowPrevIcon theme="grey" width="16px" height="16px" flip={true} />
        }
      />
    </PaginationWrapperContainer>
  )
}

export default PaginationWrapper
