import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import GridDropOverlay from '../grid/dropOverlay.js'

import Desktop from './desktop'
import Mobile from './mobile'
import useInterval from '../../utils/useInterval'

const LatestNews = ({
  getData,
  poll = true,
  type = 'desktop',
  data,
  fetching,
  disableDropLeft = false,
  disableDropBottom = false,
  hideNoData = true,
  pagination = false,
  totalHeadlines = 0,
  page = 1,
  quickLinks,
  ...rest
}) => {
  const pageLimit = 30
  const [activePage, setCurrentPage] = useState(1)
  useEffect(() => {
    if (!data.length) {
      getData({
        page: activePage,
        method: pagination ? 'archive' : 'latest'
      })
    }
  }, [])

  useEffect(() => {
    getData({
      page: activePage,
      backgroundRefresh: true,
      method: pagination ? 'archive' : 'latest'
    })
  }, [activePage])

  useInterval(() => {
    if (poll) {
      getData({
        backgroundRefresh: true,
        page: page,
        method: pagination ? 'archive' : 'latest'
      })
    }
  }, 1000 * 30)

  if (!fetching && data.length === 0 && hideNoData) {
    return rest.onDropLeft ? (
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <GridDropOverlay
          disableDropLeft={disableDropLeft}
          disableDropBottom={disableDropBottom}
          onDropBottom={rest.onDropBottom}
          onDropLeft={rest.onDropLeft}
          isLatestNews
        />
      </div>
    ) : null
  }

  const props = {
    ...rest,
    disableDropLeft,
    disableDropBottom,
    getData,
    fetching,
    data,
    pagination,
    pageLimit,
    activePage,
    setCurrentPage,
    totalHeadlines,
    page,
    quickLinks
  }

  return type === 'desktop' ? <Desktop {...props} /> : <Mobile {...props} />
}

const mapDispatchToProps = (dispatch) => {
  return {
    getData: payload => {
      dispatch({ type: 'LATEST_NEWS_REQUESTED', payload })
    },
    updateLatestNewsVisibility: payload => {
      dispatch({ type: 'UPDATE_LATEST_NEWS_VISIBLITY', payload })
    },
    hideLiveTvMobile: () => {
      dispatch({ type: 'SHOW_HIDE_LIVE_TV_MOBILE', payload: false })
    }
  }
}

const mapStateToProps = ({ latestNews, quickLinks }) => {
  const { data, fetching, totalHeadlines, page } = latestNews

  return { data, fetching, totalHeadlines, page, quickLinks }
}

export default connect(mapStateToProps, mapDispatchToProps)(LatestNews)
